import { MessageUtil } from "./MessageUtil";
import { SecurityUtil } from "./SecurityUtil";

export const RequestUtil = {
    postData: async (url, reqParams) => {
        const bearer = 'Bearer ' + SecurityUtil.getAccessToken()
        const response = await fetch(
            url,
            { 
                method: "POST", 
                body: JSON.stringify(reqParams), 
                headers: { 
                    "Authorization": bearer,
                    "Content-type": "application/json; charset=UTF-8"
                }
            }
        )
        .then(resp => {
            return resp
        })
        .catch(err => {
            return MessageUtil('error', 'Error Connection!',err.message)
        });

        if (response?.status === 401){
            SecurityUtil.setLogout()
        }
        return response.json();
        
    }
}

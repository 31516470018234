import SignatureCanvas from 'react-signature-canvas';
import { Components } from '../../Components';
import { memo, useEffect, useRef, useState } from 'react';
import { Card, FormControl, FormLabel, Grid, Stack } from '@mui/material';
import { ICON } from '../icons/Icons';
import './SignatureComponent.css'
import fondoBlanco from '../../../../assets/img/fondo-blanco.jpg';
import { S3Util } from '../../../../util/S3Util';
import { MessageUtil } from '../../../../util/MessageUtil';
import { FileUtil } from '../../../../util/FileUtil';

export const SignatureComponent = memo(({onChange, name, value = null, label, folder, urlS3, setLoader}) => {
    const [openModal, setOpenModal] = useState(false)
    const [ typeModal, setTypeModal ] = useState('create')
    const sigCanvas = useRef()

    const createSign = () => {
        setTypeModal('create')
        setOpenModal(true)
    }
    
    const viewSign = () => {
        setTypeModal('view')
        setOpenModal(true)
        
    }

    return (
        <Card variant="outlined">
            <FormControl sx={{ p: 1, width: '100%' }} component="fieldset" variant="standard">
                <FormLabel component="legend">{label}</FormLabel>
                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                        <Components.Button title='View' onClick={viewSign} icon={ICON.EYE} color='secondary' disabled={value != null ? false : true}/>
                        <Components.Button title={value != null ? 'Edit' : 'New'} onClick={createSign} icon={value != null ? ICON.EDIT :ICON.ADD} color='success'/>
                </Stack>
            </FormControl>
            {
                openModal && 
                <ModalSignature handleOpen={openModal} handleClose={()=> setOpenModal(false)} sigCanvas={sigCanvas} imageURL = {value} handleChange={onChange} name={name} type={typeModal} folder={folder} urlS3={urlS3} setLoader={setLoader}/>
            }
        </Card>
    )
})

const ModalSignature = memo(({handleOpen, handleClose, sigCanvas, imageURL = '', handleChange, name, type= 'create' || 'view', folder, urlS3, setLoader }) => {
    
    const penColor = 'black'
    
    const download = () => {
        const dlink = document.createElement("a");
        dlink.setAttribute("href", imageURL);
        dlink.setAttribute("download", "signature.png");
        dlink.click();
    };

    const create = async () => {
        setLoader(true)
        const base64 = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const base64Response = await fetch(base64);
        const blob = await base64Response.blob();
        let fileName = FileUtil.generateFileName('signature.png') 
        var file = new File([blob], fileName);
        await S3Util.uploadS3(
            {
                folder,
                file, 
                fnOk: (resp) => {
                    const { status, statusText } = resp;

                    if(status === 200){
                        if(handleChange){
                            const e = {target: { name , value: urlS3 + fileName}}
                            handleChange(e);
                            handleClose()
                            setLoader(false);
                        }
                    }
                    else{
                        MessageUtil("error","Error!", statusText);
                        handleClose()
                        setLoader(false);
                    }
                },
                fnError: (resp) => {
                    MessageUtil("error","Error!", 'Error Upload File!');
                    handleClose()
                    setLoader(false);
                }
            }
        );
    };

    useEffect(() => {
        if(type === 'create'){
            const timer = setTimeout(() => {
                if(handleOpen && imageURL!= null){
                    sigCanvas.current.fromDataURL(imageURL, { ratio: 1, width: 350, height: 200, xOffset: 50, yOffset: 50 })
                }
              }, 500);
              return () => clearTimeout(timer);
        }
    }, []);

    return (
        <Components.Modal handleOpen={handleOpen} handleClose={handleClose} title="Please Insert Signature" maxWidth="lg">
            <Grid container spacing={2} sx={{justifyContent:'center', mt: 1}}>
                {/* <Components.Input type='color' label="Color" name="color" onChange={e => setPenColor(e.target.value)} value={penColor}/> */}
                {
                    type === 'create' ?
                    <SignatureCanvas
                        penColor={penColor}
                        canvasProps={{ width: 600, height: 300, className: "sigCanvas" }}
                        ref = { sigCanvas }
                    />
                    :
                    <img
                        src={imageURL || fondoBlanco}
                        alt='sign'
                        loading="lazy"
                        style={{width: 600, height: 300}}
                    /> 
                }
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {
                            type === 'create' ?
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Components.Button title="Cancel" color='error' icon={ICON.CLOSE} onClick={handleClose} />
                                <Components.Button title="Clear" color='info' icon={ICON.DELETE} onClick={() => sigCanvas.current.clear()} />
                                <Components.Button title="Create" color='success' icon={ICON.EDIT} onClick={create} />
                            </Stack>
                            :
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Components.Button title="Close" color='error' icon={ICON.CLOSE} onClick={handleClose} />
                                <Components.Button title="Download" color='success' icon={ICON.DOWNLOAD} onClick={download} />
                            </Stack>
                        }
                </Grid>
            </Grid>
            
        </Components.Modal>
    )
})

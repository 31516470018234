export const StorageUtil = {

    getItemObject(key){
        const data = window.localStorage.getItem(key);
        if (data)
            return JSON.parse(data);
        return null;
    },

    setItemObject(key, value){
        window.localStorage.setItem(key, JSON.stringify(value));
    },

    removeItemObject(key){
        window.localStorage.setItem(key, null);
    },
    
    exist(key){
        return StorageUtil.getItemObject(key)!=null;
    }
}


import { FileUtil } from './FileUtil';
import { MessageUtil } from './MessageUtil';
import { NotificationUtil } from "./NotificationUtil";
import { SecurityUtil } from './SecurityUtil';

export class DownloadUtil {

    static async donwloadPost(url, reqParams, fileName){
        const response = await fetch(url, {
            method: "POST", 
            body: JSON.stringify(reqParams), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
        })
        .catch(err=>{
            console.error(`Error descargando ${fileName} => ${err}`);
        });
        if (response?.ok) {
            const data = await response.blob();
            return FileUtil.saveBlob(fileName, data);
        }
        let err = `Error descargando ${fileName}`;
        try {
            const respError = await response.json();
            err = respError?.message || err;
            console.error(err);
        } catch (error) {
            console.error(response);
        }
        MessageUtil('error','',err);
    }

    static async donwloadGet(url, fileId, fileName){
        const response = await fetch(url, {
            method: "GET",
        })
        .catch(err=>{
            console.error(`Error descargando ${fileName} => ${err}`);
        });
        if (response?.ok) {
            const data = await response.blob();
            console.log(data);
            return FileUtil.saveBlob(fileName, data);
        }
        let err = `Error descargando ${fileName}`;
        NotificationUtil.error(err);
        console.error(response);
    }

    static async getBase64(url, fileName){

        let response = await fetch(url, { method: "GET" }).catch(err=>{ console.error(`Error descargando ${fileName} => ${err}`); });

        if (response?.ok) {
            const data = await response.blob();

            let obj_base = {
                base_64: '',
                url,
                type_file: data.type
            }

            return obj_base;
        }
    }

    static async donwloadBlob(url, reqParams, fileName){
        const bearer = 'Bearer ' + SecurityUtil.getAccessToken();
        const response = await fetch(url, {
            method: "POST", 
            body: JSON.stringify(reqParams), 
            headers: { 
                "Authorization": bearer,
                "Content-type": "application/json; charset=UTF-8"
            } 
        })

        if (response?.status == 200) {
            const blob = await response.blob();
            return FileUtil.saveBlob(fileName, blob);
        }
        else{
            let err = `Error download report ${fileName}`;
            return MessageUtil('error',err,'');
        }
    }

    static async donwloadBlobPath(url, reqParams, fileName){
        const response = await fetch(url, {
            method: "POST", 
            body: JSON.stringify(reqParams), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
        })

        if (response?.ok) {
            // console.log(await response.blob())
            const blob = await response.blob();
            blob.name = fileName
            // let file = URL.createObjectURL(blob)
            // name, size, type
            return blob
        }
        else{
            let err = `Error download report ${fileName}`;
            return {
                error: true,
                message: err
            };
        }
    }

    static convertBase64 (blob){
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
        var base64String = reader.result;
            console.log('Base64 String - ', base64String);
            return base64String
        }
    }

    static async donwloadGet2(url, fileName){
        FileUtil.saveUrl(url, fileName);
    }

    static downloadBase64(resp){
        const link = document.createElement('a');
        link.href = resp?.mime + resp?.base64;
        link.download = resp?.fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    static modelFetch(){
        fetch("url")
        .then((response) => {
            if(!response.ok){
                throw new Error("Error HTTP: " + response.status)
            }
            return response.json()
        })
        .then((data) => console.log(data))
        .catch((error) => console.log(error))
    }
}



import { memo } from 'react'
import Checkbox from '@mui/material/Checkbox'
;
export const CheckComponent = memo(({label, name, checked, onChange, disabled = false, color = 'primary'}) => {
  
  const handleChange = (e) => {
    if(onChange){
        onChange(e);
    }
  }

  return (
      <Checkbox 
      size="small"
      label={label || ''}
      name={name}
      onChange={handleChange}
      checked={checked}
      disableRipple={disabled}
      sx={{padding: 0}}
      value={checked}
      color={color}
      />
  );
})
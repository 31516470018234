import { useState } from 'react';
/**
 * Este Hook nos servirá para validar inputs, cambios al state y reset al form
 * return{
 * data,
 * setData,
 * errors,
 * setErrors,
 * handleInputFormChange,
 * resetForm
 * }
 * 
 */
export const useFormValidation = (initialValues = {}, validateOnChange = false, validate) => {

    const [data, setData] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleInputFormChange = e => {        
        const { name, value, type, checked } = e.target;
        setData({...data, [name]: (type === 'checkbox') ? checked : value});
        if (validateOnChange){
            validate({...data, [name]: (type === 'checkbox') ? checked : value })
        }
    }

    const resetForm = () => {
        setData(initialValues);
        setErrors({})
    }

    return {
        data,
        setData,
        errors,
        setErrors,
        handleInputFormChange,
        resetForm
    }
}
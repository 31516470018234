import { Stack } from '@mui/material'

export const StackComponent = ({children, sx, justifyContent = 'center', alignItems = 'center', spacing = 1, table = false}) => {
  
  return (
    <Stack 
      spacing={spacing} 
      direction={
          { 
              xs: table ? 'row' : 'column', 
              sm: 'row', 
              md: 'row', 
              lg: 'row', 
              xl: 'row', 
              xxl: 'row' 
          }
      } 
      justifyContent={ 
        { 
          xs: ['flex-end','flex-start','space-between'].includes(justifyContent) ? 'center' : justifyContent, 
          sm: justifyContent, 
          md: justifyContent, 
          lg: justifyContent, 
          xl: justifyContent, 
          xxl: justifyContent 
        }
      }
      alignItems={ 
        { 
          xs: ['flex-end','flex-start','space-between'].includes(justifyContent) ? justifyContent : (justifyContent === 'center') ? justifyContent : 'flex-start', 
          sm: alignItems, 
          md: alignItems, 
          lg: alignItems, 
          xl: alignItems, 
          xxl: alignItems 
        }
      }
      // "center"
      sx={sx}
      >
      { (Array.isArray(children) || typeof children === 'object') ? children : <></> }
    </Stack> 
  )
}

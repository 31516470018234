import { ButtonComponent, ButtonIconComponent, ButtonIcon } from "./form/button/ButtonComponent";
import { FileComponent } from "./form/file/FileComponent";
import { InputComponent } from "./form/input/InputComponent";
import { IMaskComponent } from "./form/imask/IMaskComponent";
import { NumberComponent } from "./form/number/NumberComponent";
import { SearchComponent } from "./form/search/SearchComponent";
import { SelectComponent } from "./form/select/SelectComponent";
import { ModalComponent } from "./form/modal/ModalComponent";
import { CheckComponent }from './form/check/CheckComponent';
import { SwitchComponent }from './form/switch/SwitchComponent';
import { ConfirmComponent } from "./form/confirm/ConfirmComponent";
import { TextAreaComponent } from "./form/textarea/TextAreaComponent";
import { RadioComponent } from "./form/radio/RadioComponent";
import { Title } from './form/title/TitleComponent';
import { AuditoriaComponent } from './form/footer/AuditoriaComponent';
import { SimpleBackdrop } from '../loading/LoadingPage';
import { FormConfirmComponent } from "./form/confirm/FormConfirmComponent";
import { NotRecords } from "./table/NotRecords";
import { SignatureComponent } from "./form/signature/SignatureComponent";
import { CameraComponent } from "./form/camera/CameraComponent";
import { StackComponent } from "./form/stack/StackComponent";

export const Components = {
    Button: ButtonComponent,
    ButtonIconComponent,
    File: FileComponent,
    Input: InputComponent,
    Search: SearchComponent,
    Select: SelectComponent,
    Modal: ModalComponent,
    Confirm: ConfirmComponent,
    TextArea: TextAreaComponent,
    Title,
    Auditoria: AuditoriaComponent,
    SimpleBackdrop,
    Check: CheckComponent,
    Radio: RadioComponent,
    FormConfirm: FormConfirmComponent,
    NotRecords,
    ButtonIcon,
    Switch: SwitchComponent,
    Number: NumberComponent,
    IMask: IMaskComponent,
    Signature: SignatureComponent,
    Camera: CameraComponent,
    Stack: StackComponent
}

// export default Components;
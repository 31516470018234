import { TableCell, TableRow } from "@mui/material"

export const NotRecords = ({colSpan}) => {
    return(
        <>
            <TableRow key={`table_row_not_records`}>
                <TableCell p={10} colSpan={colSpan || 1} align="center">
                    No matching records found
                </TableCell>
            </TableRow>
        </>
    )
}
import iziToast from 'izitoast';
import 'izitoast-react/dist/iziToast.css';

export class NotificationUtil {
    static error = (msg) => {
        iziToast['error']({
            title: '',
            message: msg,
            timeout: 5000,
            // overlay: true,
            position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
            progressBar: false
        });  
    };

    static success = (msg) => {
          iziToast['success']({
              title: '',
              message: msg,
              timeout: 5000,
              // overlay: true,
              position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
              progressBar: false
          });  
    };
}



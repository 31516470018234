import { memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ButtonComponent } from '../button/ButtonComponent';
import { makeStyles } from '@mui/styles';
import { ICON } from '../icons/Icons';

const useStyles = makeStyles({
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        // backgroundColor: theme.palette.secondary.light,
        color: "#ffa500",
        '&:hover': {
            // backgroundColor: theme.palette.secondary.light,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        }
    },
 });

export const FormConfirmComponent = memo(({ confirmForm, setConfirmForm, children, form }) => {
    const clases = useStyles();

    return (
        <Dialog open={confirmForm.isOpen}
        fullWidth={true}
        maxWidth={"sm"}>
            <DialogTitle className={clases.dialogTitle}>
                {/* <IconButton disableRipple className={clases.titleIcon}>
                    <NotListedLocationIcon />
                </IconButton> */}
            </DialogTitle>
            <DialogContent className={clases.dialogContent}>                
                {children}
            </DialogContent>
            <DialogActions className={clases.dialogAction}>
                <ButtonComponent
                title="Cancelar"
                className={"button-danger"}
                icon={ICON.CLOSE}
                onClick={() => setConfirmForm({ ...confirmForm, isOpen: false })} 
                />
                <ButtonComponent
                title="Aceptar"
                className={"button-blue"}
                icon={ICON.CHECK}
                onClick={() => {
                    confirmForm.onConfirm(form);
                    setConfirmForm({ ...confirmForm, isOpen: false })}} 
                />
            </DialogActions>
        </Dialog>
    )
})
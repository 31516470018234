import React, { Suspense } from 'react';
// import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthModuleRoutes } from '../auth_module/AuthModuleRoutes';
import ContainerLayout from '../framework/layout/ContainerLayout';
import { SecurityUtil } from '../util/SecurityUtil';
import { UrlUtil } from '../util/UrlUtil';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { SimpleBackdrop } from '../framework/loading/LoadingPage';
import { DashboardLayout } from '../framework/layout/dashboard-layout/DashboardLayout';

export const AppRouter = ({ match, location }) => {    
    const [ isLoad, setIsLoad ] = React.useState(false);
    const [ isFull, setIsFull ] = React.useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = React.useState(false);

    React.useEffect(() => {
        setIsLoad(true);
        if (SecurityUtil.isAuthenticated()){
            setIsLoggedIn(true);
        }
        else {
            const par = UrlUtil.paramsUrlToObject();
            if (par && par?.params){
                let params = JSON.parse(atob(par.params));
                if (params?.full===1){
                    SecurityUtil.setFull();
                }

                if(params?.username){
                    setIsLoad(false);
                    SecurityUtil.setUrlWithLogin(par.params);
                    SecurityUtil.loginWithoutReload({
                        username: params.username, 
                        password: params.password, 
                        fnOk: ()=> {
                            setIsLoggedIn(true);
                            setIsLoad(true);
                        },
                        fnError: () => {
                            setIsLoad(true);
                        }
                    });
                }
            }
        }
        setIsFull(SecurityUtil.isFull());
    }, []);

    if (!isLoad){
        return (<SimpleBackdrop open={isLoad}/>)
    }

    if (isFull){
        return (
            <Suspense fallback={<SimpleBackdrop open={true}/>}>
                <Switch>
                    <Route path="/" component={ContainerLayout} />    
                </Switch>
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<SimpleBackdrop open={true}/>}>
            <Switch>   
                <PublicRoute path="/auth" component={ AuthModuleRoutes } isAuthenticated={ isLoggedIn } />
                <PrivateRoute isAuthenticated={ isLoggedIn } path="/" component={ DashboardLayout } />
                <Redirect to="/auth/login" />
            </Switch>
        </Suspense>
    )
}
import iziToast from 'izitoast';
import 'izitoast-react/dist/iziToast.css';

export const MessageUtil = (type, title, message) => {
    iziToast[type]({
        title,
        message,
        timeout: 5000,
        // zindex: 999,
        // overlay: true,
        position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        // progressBar: true
    });
}

import { EnvConstants } from "../EnvConstants";
import { RequestUtil } from "./RequestUtil";
import { DownloadUtil } from "./DownloadUtil";
import { FileUtil } from "./FileUtil";
import axios from 'axios';

export class S3Util {

    static getTemporalPutUrl({ folder, fileName, fnOk, fnError, contentType}){
        // const { folder, fileName, fnOk, fnError, contentType} = config;
        RequestUtil.postData(EnvConstants.GET_FW_URL_UPLOAD_PRESIGNED_URL_PUT(), { params: { folder, fileName, contentType } })
        .then( resp => {
            if (fnOk)
                fnOk(resp);
        })
        .catch(error=>{
            if (fnError)
                fnError(error);
        });
    }

    static getTemporalGetUrl({ folder, fileName, fnOk, fnError}){
        // const { folder, fileName, fnOk, fnError} = config;
        RequestUtil.postData(EnvConstants.GET_FW_URL_UPLOAD_PRESIGNED_URL_GET(), { params: { folder, fileName } })
        .then( resp => {
            if (fnOk)
                fnOk(resp);
        })
        .catch(error=>{
            if (fnError)
                fnError(error); 
        });
    }

    static async getTemporalGetUrlAsync(fileName, folder){
        let resp = await RequestUtil.postData(EnvConstants.GET_FW_URL_UPLOAD_PRESIGNED_URL_GET(), { params: { folder, fileName } });
        return resp.dataObject.url;
    }

    static async getTemporalDeleteUrlAsync(fileName, folder){
        let resp = await RequestUtil.postData(EnvConstants.GET_FW_URL_UPLOAD_PRESIGNED_URL_DELETE(), { params: { folder, fileName } });
        return resp.dataObject.url;
    }


    static async donwload(fileName, folder){
        let url = await S3Util.getTemporalGetUrlAsync(fileName,folder);
        DownloadUtil.donwloadGet(url, fileName);
    }

    static async preview(fileName, folder){
        let url = await S3Util.getTemporalGetUrlAsync(fileName,folder);
        
        return await DownloadUtil.getBase64(url, fileName);
    }

    static async getUrlS3(fileName, folder){
        let url = await S3Util.getTemporalGetUrlAsync(fileName,folder);
        return url;
    }

    static async delete(fileName, folder){
        let url = await S3Util.getTemporalDeleteUrlAsync(fileName,folder);
        fetch(url, {
            method: 'DELETE',
        })
        .then(res => res.json()) // or res.json()
        .then(res => console.log(res));
    }
    
    static downloadS3 = async(fileId, folder, fileName) => {
        S3Util.getTemporalGetUrl({ 
            folder, 
            fileName: fileId,
            fnOk: (resp) => {
                if (resp?.dataObject?.url){
                    DownloadUtil.donwloadGet(resp.dataObject.url, fileId, fileName);
                }
            },
            error: (error) => {
                console.error(error);
            }
        });
    }

    static uploadS3 = async({folder, file, fnOk, fnError }) => {
        const { name, size, type } = file;

        const fileId = name;
    
        S3Util.getTemporalPutUrl({ 
            folder, 
            fileName: fileId,
            contentType: type,
            fnOk: (resp) => {

                const { url } = resp?.dataObject;
                
                let objectUpload = { fileId, name, size, type, url }

                axios.put(url, file, {
                    headers: {
                        'Content-Type': type
                    }
                }).then( ax => {
                    const { status, statusText } = ax;

                    objectUpload = {...objectUpload, status, statusText}

                    if(fnOk){
                        fnOk(objectUpload);
                    }
                })
                .catch(err => {
                    
                    const { status, statusText } = err;
                    
                    objectUpload = {...objectUpload, status, statusText}
                    
                    if(fnError){
                        fnError(objectUpload);
                    }
                })

                
            },
            error: (error) => {
                fnError(error);
                console.error(error);
            }
        });
    }

}


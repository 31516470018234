import { memo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { ICON } from '../icons/Icons'
import DialogContent from '@mui/material/DialogContent'
import { DialogActions, Grid } from '@mui/material'
import { Components } from '../../Components'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const BootstrapDialogTitle = ({ children, onClose, ...other}) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#070942",
          }}
        >
          {ICON.CLOSE}
        </IconButton>
      ) : <></>}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
};


export const ModalComponent = ({handleOpen = false, handleClose, children, title, maxWidth, fullWidth = false, fullScreen = false, actions = []}) => {

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={handleOpen}
      maxWidth={maxWidth || "md"}     
      fullWidth={fullWidth}    
    >
      <BootstrapDialogTitle className={"icon-blue"} onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      {
        actions.length > 0
        &&
        <DialogActions>
          <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Components.Stack>
                      {
                        actions.map((x, idx) => (
                          <Components.Button title={x.title} icon={x.icon} onClick={x.onClick} color={x.color} key={`button_${idx}`}/>
                        ))
                      }
                    </Components.Stack>
                </Grid>
            </Grid>
            
        </DialogActions>
      }
    </Dialog> 
  );
}

export const ModalPdfComponent = memo(({handleOpen = false, handleClose, title = '', maxWidth, pathPdf = ''}) => {

  return (
      <BootstrapDialog
        onClose={handleClose}   
        open={handleOpen}
        maxWidth={maxWidth || "md"} 
        fullWidth={true}    
      >
        <BootstrapDialogTitle className={"icon-blue"} onClose={handleClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <embed    
          width="100%" 
          height="750px" 
          src={`${pathPdf}`} 
          type="application/pdf"
            />
        </DialogContent>
      </BootstrapDialog> 
  );
})
import React, { lazy, Suspense, useContext, useEffect } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
    useLocation
  } from "react-router-dom"
import { SimpleBackdrop } from '../framework/loading/LoadingPage';
import { Context } from '../framework/layout/ContainerLayout';

const MasterRoutes = lazy(() => import('./masters/MasterRoutes'));
const SecurityRoutes = lazy(() => import('./security/SecurityRoutes'));
const UploadRoutes = lazy(() => import('./upload/UploadRoutes'));
const InvoiceRoutes = lazy(() => import('./invoice/InvoiceRoutes'));
const PayrollRoutes = lazy(() => import('./payroll/PayrollRoutes'));
const ReportRoutes = lazy(() => import('./report/ReportRoutes'));
const ManagerRoutes = lazy(() => import('./manager/ManagerRoutes'));
const ValidationsRoutes = lazy(() => import('./validations/ValidationsRoutes'));
const ChecksRoutes = lazy(() => import('./checks/ChecksRoutes'));
const HomeRoutes = lazy(() => import('./home/HomeRoutes'));
const PowerBIRoutes = lazy(() => import('./power-bi/PowerBIRoutes'));

export const ModulesRoutes = () => {
    const { profileUser } = useContext(Context);
    const { path } = useRouteMatch();
    const { push } = useHistory();
    let location = useLocation();
    
    useEffect(() => {
        if(profileUser.urlHome){
            if(location?.pathname === '/' || location?.pathname === undefined){
                push(profileUser.urlHome)
            }
        }
    }, [])

    return (
         <Suspense fallback={<SimpleBackdrop open={true}/>}>
            <Switch>
                <Route path={`${path}security`} component={SecurityRoutes}/>
                <Route path={`${path}masters`} component={MasterRoutes}/>
                <Route path={`${path}upload`} component={UploadRoutes}/>
                <Route path={`${path}invoice`} component={InvoiceRoutes}/>
                <Route path={`${path}payroll`} component={PayrollRoutes}/>
                <Route path={`${path}report`} component={ReportRoutes}/>
                <Route path={`${path}manager`} component={ManagerRoutes}/>
                <Route path={`${path}validations`} component={ValidationsRoutes}/>
                <Route path={`${path}checks`} component={ChecksRoutes}/>
                <Route path={`${path}home`} component={HomeRoutes}/>
                <Route path={`${path}power-bi`} component={PowerBIRoutes}/>
            </Switch>
         </Suspense>
    )
}

//https://learnwithparam.com/blog/how-to-handle-query-params-in-react-router/
export const UserUtil = {

    getUserId: () => {
        if(localStorage.getItem('AUTH_USER') !== '') {
            const auth = localStorage.getItem('AUTH_USER');
            const user = JSON.parse(auth);
            return user[0].userId;
        } else {
            return null;
        }
    },

    getUser: () => {
        if(localStorage.getItem('AUTH_USER') !== '') {
            const auth = localStorage.getItem('AUTH_USER');
            const user = JSON.parse(auth);
            return  { user: user[0].user, profile: user[0].profile, position: user[0].position } 
        } else {
            return null;
        }
    },

    getKey: () => {
        return (Math.random() + 1).toString(36).substring(7);
    },

    getUserAuth: () => {
        if(localStorage.getItem('AUTH_USER') !== '') {
            const auth = localStorage.getItem('AUTH_USER');
            const user = JSON.parse(auth);
            return user[0];
        } else {
            return null;
        }
    }
}
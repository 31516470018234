import { memo, useCallback, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { decimalFormat } from '../../../../util/FormatUtil'

export const NumberComponent = ({key, label, value, name, onChange, prefix = '', decimalScale = 2, disabled = false, align = 'left', format = true, error = null, sx}) => {

    const handleChange = (e) => {
        if(onChange){
            onChange(e);
        }
    }

    return (
        <NumericFormat
            customInput={TextField}
            label={label} 
            defaultValue={ format ? decimalFormat(value) : value }
            value={ value }
            variant='outlined' 
            size='small' 
            // prefix={prefix}
            onInput={handleChange}
            // onChange={handleChange}
            decimalSeparator='.'
            decimalScale={decimalScale}
            name={name}
            sx={{ width: '100%', ...sx }}
            InputProps={{    
                inputProps: { 
                    readOnly: disabled,
                    style: { textAlign: align }
                },
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
            }}
            {...(error && {error:true, helperText: error, required: true})}
        />
    )
}
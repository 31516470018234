import React from 'react'
import { Switch, Route } from "react-router-dom";
import { ModulesRoutes } from '../modules/ModulesRoutes';

export const ContainerRoutes = () => {
    return (
        <Switch>
            <Route path="/" component={ModulesRoutes} />    
        </Switch>
    )
}
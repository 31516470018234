import { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export const Title = memo((props) => {
  return (
    <Typography component="h2" variant="h6" sx={{...props.sx}} gutterBottom {...props}>
      {props.children}
    </Typography>
  );
})

Title.propTypes = {
  children: PropTypes.node,
}
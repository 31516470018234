import { MessageUtil } from "../../util/MessageUtil";
import { RequestUtil } from "../../util/RequestUtil";

export const LoadUtil = {

    loadByQueryId: (config) => {  
        const { url, fnOk, setLoader = () => {} } = config;
        setLoader(true)
        const reqParams = LoadUtil.getReqParams(config);
        RequestUtil.postData(url, reqParams, setLoader)
        .then( resp => {
            const { error, message } = resp
            if(error){
                setLoader(false)
                return MessageUtil('error', message || error || '', '')
            }
            else{
                fnOk(resp)
                setLoader(false)
            }
        })
        .catch(() => {
            setLoader(false)
        })
    },

    getReqParams: (config) => {
        let {reqParams, queryId, params, orderBy, IsPaginated, StartRow, EndRow} = config;
        reqParams = reqParams || {};
        reqParams.params = {...reqParams.params, ...params};
        reqParams.queryId = queryId;
        reqParams.orderBy = orderBy || '';
        reqParams.isPaginated = IsPaginated;
        reqParams.startRow = StartRow;
        reqParams.endRow = EndRow;
        return reqParams;
    }
}
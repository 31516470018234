import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { FormHelperText } from '@mui/material';

export const RadioComponent = ({ label = '', value = '', onChange, name, list = [], row = false, error = ''}) => {
  
  return (
    <FormControl error={!!error} variant="outlined">
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={onChange}
      >
        {list.map(x => (
          <FormControlLabel key={x.value} value={x.value} control={<Radio size='small' />} label={x.label} />
        ))}
      </RadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EnvConstants } from '../../EnvConstants';
import { ContainerRoutes } from '../../routers/ContainerRoutes';
import { Components } from '../components/Components'; 
import { LoadUtil } from '../util/LoadUtil';

export const Context = createContext({});

const ContainerLayout = ({ getProfiles }) => {
  let location = useLocation();
  const [ route, setRoute ] = useState('')
  const [ loader, setLoader ] = useState(false)
  const [ profileUser, setProfileUser ] = useState({})

  const loadProfileUser = () => {
    LoadUtil.loadByQueryId({
        url: EnvConstants.GET_FW_FORM_URL_LIST_BASE(),
        queryId: 62,
        params: { route },
        fnOk: (resp) => {
          setProfileUser(resp.dataObject);
          getProfiles(resp.dataObject)
        }
    });
  }

  useEffect(() => {
      let arrayLocation = location?.pathname === '/' ? '' : location?.pathname?.split('/')
      let pathRoute = location?.pathname === '/' ? '' : `/${arrayLocation[1]}/${arrayLocation[2]}`
      setRoute(pathRoute || 'home')
  },[]);

  useEffect(() => {
    if(route !== ''){
      loadProfileUser()
      return () => {
        setProfileUser({})
        getProfiles({})
      }
    }
  },[route]);
    
  return (
    (Object.keys(profileUser).length > 0) &&
    <Context.Provider value={{ loader, setLoader, profileUser, setProfileUser }}>
      <ContainerRoutes/>
      <Components.SimpleBackdrop open={ loader }/>
    </Context.Provider>
  );
};

export default ContainerLayout;
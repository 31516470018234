import { memo, useRef, useState, useCallback } from 'react';
import Webcam from "react-webcam";
import { Components } from '../../Components';
import { Card, FormControl, FormLabel, Grid, Stack } from '@mui/material';
import { ICON } from '../icons/Icons';
import fondoBlanco from '../../../../assets/img/fondo-blanco.jpg';
import { S3Util } from '../../../../util/S3Util';
import { FileUtil } from '../../../../util/FileUtil';
import { MessageUtil } from '../../../../util/MessageUtil';

export const CameraComponent = memo(({onChange, name, value = null, label, folder, urlS3, setLoader }) => {
    const [openModal, setOpenModal] = useState(false)
    const [ typeModal, setTypeModal ] = useState('create')
    const webcamRef = useRef(null);

    const createPhoto = () => {
        setTypeModal('create')
        setOpenModal(true)
    }
    
    const viewPhoto = () => {
        setTypeModal('view')
        setOpenModal(true)
        
    }

    const handleChangeUpload = async (event) => {
        setLoader(true)
        let file = event.target.files[0]
        const folder = `repository-timesheet-files/onboarding/images`;
        const urlS3 = 'https://repository-timesheet-files.s3.us-east-2.amazonaws.com/onboarding/images/' + file.name
        await S3Util.uploadS3(
            {
                folder,
                file, 
                fnOk: (resp) => {
                    const { status, statusText } = resp;

                    if(status === 200){
                        if(onChange){
                            const e = {target: { name , value: urlS3}}
                            onChange(e);
                            setLoader(false)
                        }
                    }
                    else{
                        MessageUtil("error","Error!", statusText)
                        setLoader(false)
                    }
                },
                fnError: (resp) => {
                    MessageUtil("error","Error!", 'Error Upload File!');
                    setLoader(false)
                }
            }
        );
    };

    return (
        <Card sx={{  border: '1px solid #D3D3D3' }}>
            <FormControl sx={{ p: 1, width: '100%' }} component="fieldset" variant="standard">
                <FormLabel component="legend">{label}</FormLabel>
                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Components.Button title='View' onClick={viewPhoto} icon={ICON.EYE} color='secondary' disabled={value != null ? false : true}/>
                    <Components.Button title='Camera' onClick={createPhoto} icon={ICON.CAMERA}/>
                    <Components.File title='Upload' onChange={handleChangeUpload} icon={ICON.UPLOAD} accept='image/png, image/jpeg'/>
                </Stack>
            </FormControl>
            {
                openModal && 
                <ModalCamera handleOpen={openModal} handleClose={()=> setOpenModal(false)} webcamRef={webcamRef} imageURL = {value} handleChange={onChange} name={name} type={typeModal} folder={folder} urlS3={urlS3} setLoader={setLoader}/>
            }
        </Card>
    )
})

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const ModalCamera = memo(({handleOpen, handleClose, webcamRef, imageURL, handleChange, name, type = 'create' || 'view', folder, urlS3, setLoader}) => {
    
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

    const videoConstraints = {
        facingMode: FACING_MODE_USER
    };

    const handleChangeCamera = useCallback(() => {
        setFacingMode(
        prevState =>
            prevState === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER
        );
    }, []);

    const download = () => {
        const dlink = document.createElement("a");
        dlink.setAttribute("href", imageURL);
        dlink.setAttribute("download", "signature.png");
        dlink.click();
    };

    const capture = useCallback(async () => {
        setLoader(true)
        const base64 = webcamRef.current.getScreenshot();
        const base64Response = await fetch(base64);
        const blob = await base64Response.blob();
        let fileName = FileUtil.generateFileName('camera.jpg') 
        var file = new File([blob], fileName);
        await S3Util.uploadS3(
            {
                folder,
                file, 
                fnOk: (resp) => {
                    const { status, statusText } = resp;

                    if(status === 200){
                        if(handleChange){
                            const e = {target: { name , value: urlS3 + fileName} }
                            handleChange(e);
                            handleClose()
                            setLoader(false);
                        }
                    }
                    else{
                        MessageUtil("error","Error!", statusText);
                        handleClose()
                        setLoader(false);
                    }
                },
                fnError: (resp) => {
                    MessageUtil("error","Error!", 'Error Upload File!');
                    handleClose()
                    setLoader(false);
                }
            }
        );
    }, [webcamRef]);

    return (
        <Components.Modal handleOpen={handleOpen} handleClose={handleClose} title="Please Shot Capture" maxWidth="sm">
            <Grid container spacing={2} sx={{textAlign:'center', p: 2}}>
                <Grid item>
                    {
                        type === 'create' ?
                        <Webcam 
                            audio={false}
                            screenshotFormat="image/jpeg"
                            width='100%'
                            videoConstraints={{
                                ...videoConstraints,
                                facingMode
                            }}
                            ref={webcamRef}
                            mirrored={true}
                        />
                        :
                        <image
                            alt='photo'
                            src={imageURL || fondoBlanco}ñ
                            loading="pimage"
                            style={{width: 500, height: 450}}
                        /> 
                    }
                
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {
                        type === 'create' ?
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Components.Button title="Cancel" color='error' icon={ICON.CLOSE} onClick={handleClose} />
                            <Components.Button title="Capture" color='success' icon={ICON.CAMERA} onClick={capture} />
                            <Components.Button title="Change Camera" color='info' icon={ICON.CAMERA_SWITCH} onClick={handleChangeCamera} />
                        </Stack>
                        :
                        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Components.Button title="Close" color='error' icon={ICON.CLOSE} onClick={handleClose} />
                            <Components.Button title="Download" color='success' icon={ICON.DOWNLOAD} onClick={download} />
                        </Stack>
                    }
                </Grid>
            </Grid>
            
        </Components.Modal>
    )
})
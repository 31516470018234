import { memo } from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'

export const FileComponent = memo(({title, accept, icon, onChange, className, multiple, disabled, size, color = 'primary'}) => {

  const handleChange = (e) => {

    if(onChange){
      onChange(e);
    }
    e.target.value = null
  }
  return (
      // {
        (size === 'small') ?
        <Tooltip title={title || ''} placement="top">
          <IconButton color='secondary' variant="contained" component="label" size="small" disabled={disabled || false}>
            <input hidden accept={accept || '*'} multiple={multiple || false} type="file" onChange={handleChange} />
            {icon}
          </IconButton>
        </Tooltip>
        :
        <Button sx={{textTransform: 'none'}} className={disabled ? 'button-disabled' : (className || '')} color={color} variant="contained" component="label" size="small" startIcon={icon} disabled={disabled || false}>
          {title}
          <input hidden accept={accept || '*'} multiple={multiple || false} type="file" onChange={handleChange} />
        </Button>
  );
})
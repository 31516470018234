import moment from 'moment';
import * as fs from 'file-saver';
import { MIME_TYPE } from '../constants/constants';
import * as XLSX from "xlsx";

export class FileUtil {
    static ramdom(minimo,maximo){
        return Math.round(Math.random() * (maximo - minimo) + minimo);
    }
    static generateFileName_ramdom_YYYYMMDD_HHmmss = (originalName) => {
        let ext = FileUtil.getExtension(originalName);
        return 'file_' + FileUtil.ramdom(1, 1000000) + '_' +  moment(new Date()).format('YYYYMMDD_HHmmss') + (ext ? "." + ext : "");
    }
    static generateFileName_YYYYMMDD_HHmmss = (originalName) => {
        let ext = FileUtil.getExtension(originalName);
        return 'file_' +  moment(new Date()).format('YYYYMMDD_HHmmss') + (ext ? "." + ext : "");
    }
    static generateFileName = (originalName) => {
        return 'file_' +  moment(new Date()).format('YYYYMMDD_HHmmss') + '_' + originalName;
    }
    static getExtension(fileName){
        let parts = fileName.split('.');
        return parts?.length > 0 ? parts[parts.length-1].toLowerCase() : "";
    }   
    static getMimeTypeFromFileName(fileName){
        let extension = FileUtil.getExtension(fileName);
        //console.log(MIME_TYPE);
        let type = MIME_TYPE[extension];
        return type ? type : MIME_TYPE.default;
    }
    static saveBlob(fileName, blob){
        // let media = FileUtil.getMimeTypeFromFileName(fileName);
        // console.log(`FileUtil.saveBlob: ${fileName}, type: ${media}`);
        // let blobTemp = new Blob([blob],{ type: media});
        fs.saveAs(blob, fileName);
    }
    static saveUrl(url, fileName){
        fs.saveAs(url, fileName);
    }

    static readExcelFile (result) {
        const bstr = result;
        const workbook = XLSX.read(bstr, { type: "binary", cellText: true });
        const { SheetNames, Sheets } = workbook;  
        const sheetName = SheetNames[0];
        const woorSheet = Sheets[sheetName];
        const data = XLSX.utils.sheet_to_row_object_array(woorSheet, { header: 0, defval: '', raw:false });

        return data || [];
    }
}



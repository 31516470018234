import React, { useState, useEffect, memo } from 'react';
import { Grid } from '@mui/material';
import { UserUtil } from '../../../../util/UserUtil';
import moment from 'moment';
import { Components } from '../../Components';

export const AuditoriaComponent = memo(({value}) => {
    
  const [timer, setTimer] = useState('');
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    let counter;
    setToggle(true);
    if (toggle) {
      counter = setInterval(() => setTimer(timer => timer = moment().format('DD/MM/YYYY hh:mm:ss A')), 1000);
    }
    return () => {
        clearInterval(counter);
    };
  }, [toggle, value]);

  return (
    <>    
        <Components.Title>Auditory</Components.Title> 
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1} xxl={1}>
                <h6>Created By:</h6>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={1} xxl={1}>
                <h6 className='bg-base'>{value?.createdBy || UserUtil.getUserAuth().username}</h6>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1} xxl={1}>
                <h6 className='h6-space'>Creation Date:</h6>                                                
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2} xxl={2}>
                <h6 className='bg-base'>{  value?.createdAt || timer}</h6>                                                
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1} xxl={1}>
                <h6>Modified By:</h6>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={1} xxl={1} >
                <h6 className='bg-base'>{value?.modifiedBy || ''}</h6>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1} xxl={1}>
                <h6>Modification Date:</h6>                                                
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2} xxl={2}>
                <h6 className='bg-base'>{value?.modifiedAt || ''}</h6>                                                
            </Grid>
        </Grid>
        <br/>
    </>    
  );
})
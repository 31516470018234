export const URL_MODE = {
    NEW    : 'new', // url del tipo =>  http://localhost:3000/[module-name]/[page-name]/new 
    UPDATE : 'update', // url del tipo =>  http://localhost:3000/[module-name]/[page-name]/[id]/update 
    READ   : 'read', // url del tipo =>  http://localhost:3000/[module-name]/[page-name]/[id] 
    OTHER  : 'other' //
};

export const PAGE_MODE = {
    NEW    : 'new',
    UPDATE : 'update', 
    READ   : 'read', 
    OTHER  : 'other'
};

export const MODE_FILTER = {
    SHOW  : 'show',
    HIDE  : 'hide',
    READ  : 'read',
    EDIT  : 'edit'
};

export const MODE_SHORTCUT = {
    ALL  : 'ALL'
};

export const DATE_FORMAT = {
    DDMMYYYY : 'DD-MM-YYYY',
    DDMMYYYY_HHmm : 'DD-MM-YYYY HH:mm',
    DDMMYYYY_HHmmss : 'DD-MM-YYYY HH:mm:ss',
    YYYYMMDD : 'YYYY-MM-DD',
    YYYYMMDD_HHmm : 'YYYY-MM-DD HH:mm',
    YYYYMMDD_HHmmss : 'YYYY-MM-DD HH:mm:ss',
    HHmm : 'HH:mm',
    HHmmss : 'HH:mm:ss'
};

export const FIELD_TYPE = {
    WITH_ERROR: 'with_error',
    GROUP: 'group',
    TEXT: 'text',
    SELECT: 'select',
    DATE: 'date',
    NUMBER: 'number',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
    TEXTAREA: 'textarea',
    STATIC: 'static',
    SWITCH: 'switch',
    UPLOAD: 'upload',
    DIVIDER: 'divider',
    HIDDEN: 'hidden',
    EDITOR: 'editor',
    PREVIEW: 'preview',
    GALLERY: 'gallery',
    OBJECT: 'object',
    SEARCH: 'search',
    DRAGGER: 'dragger',
    DECIMAL: 'decimal',
    CHECK: 'check',
    BUTTON: 'button',
    CALENDAR: 'calendar',
    MAPS: 'maps'
}

export const MIME_TYPE = {
    default: 'application/octet-stream',
    bin: 'application/octet-stream',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
    doc: 'application/msword',
    ppt: 'application/vnd.ms-powerpoint',
    png: 'image/png',
    zip: 'application/zip',
    jpe: 'image/jpeg',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    txt: 'text/plain'
}

export const PastelColors = {
    orange: '#FFDBB3',
    red: '#FF9696',
    green: '#BDFFDB',
    yellow: '#FFFD95',
    blue: '#95C8FF',
    purple: '#C895FF',
    pink: '#FF95F1',
    gray: '#DADADA'
}

import { memo } from 'react'
import { Grid, Stack, Switch, FormControlLabel, Card, FormLabel } from '@mui/material'

export const SwitchComponent = memo(({label, name, checked = false, onChange, value, labelPlacement = 'end'}) => {
  return (
    <Card variant="outlined">
      <Stack direction="row" spacing={1} alignItems="center">
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <FormLabel component="legend" sx={{ml: 1}}>{label}</FormLabel>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <FormControlLabel
            control={
              <Switch color="primary" checked={checked} value={checked} onChange={onChange} name={name} />
            }
            label={checked ? 'Yes' : 'No'}
          />
        </Grid>
      </Stack>
    </Card>
  );
})
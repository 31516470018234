import { memo } from 'react'
import { TextField } from '@mui/material'
import { IMaskMixin } from "react-imask"

const MaskedTextField = IMaskMixin(
	({ inputRef, defaultValue, ...otherProps }) => (
		<TextField {...otherProps} inputRef={inputRef} value={defaultValue || ''} />
	)
)

const MaskedField = ({ name, value, onChange, label, ...otherProps }) => {
	return (
		<MaskedTextField
			id={name}
			name={name}
			label={label}
			defaultValue={value}
			onAccept={val => onChange({ target: { name, value: val } })}
			{...otherProps}
		/>
	)
}

export const IMaskComponent = memo(({label, value = '', onChange, name, disabled, className, mask, error }) => {

  const handleChange = (event) => {
    onChange(event)
  };

  return (
        <MaskedField
          fullWidth
          mask={mask}
          label={label}
          onChange={handleChange}
          name={name}
          value={value}
          size={'small'} 
          disabled = {disabled}
          variant={'outlined'} 
          {...(error && {error:true, helperText: error, required: true})}
          />
  );
})
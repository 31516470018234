import { EnvConstants } from "../EnvConstants";
import { MessageUtil } from "./MessageUtil";
import { RequestUtil } from "./RequestUtil";
import { StorageUtil } from "./StorageUtil";
import { WindowUtil } from "./WindowUtil";

export const SecurityUtil = {
    login : async ({username, password}, setLoader) => {
        setLoader(true)
        RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", {username, password})
        .then( resp => {
            const { error, message } = resp
            if(error){
                setLoader(false)
                return MessageUtil('error', message || error || '', '')
            }
            else{
                SecurityUtil.setLogin(resp);
                setLoader(false)
                WindowUtil.reload();
            }
        })
        .catch(() => {
            setLoader(false)
        })
    },

    loginWithoutReload: async ({username, password, fnOk, fnError}) => {
        try {
            let resp = await RequestUtil.postData(EnvConstants.GET_URL_AUTH() + "/login", {username, password});
            if (resp){
                SecurityUtil.setLogin(resp);
                if (fnOk){
                    fnOk();
                }
            }
        } catch (error) {
            if (fnError){
                fnError();
            }
        }
    },

    setFull: () => {
        StorageUtil.setItemObject('AUTH_FULL', {full:1});
    },
    
    isFull: () => {
        return StorageUtil.getItemObject('AUTH_FULL')!=null;
    },

    setLogin: (resp) => {
        StorageUtil.setItemObject('AUTH_USER', resp.info?.dataObject?.user);
        resp.info = null;
        StorageUtil.setItemObject('AUTH', resp);
    },

    setLogout: () => {
        StorageUtil.removeItemObject('AUTH');
        StorageUtil.removeItemObject('AUTH_USER');
        WindowUtil.reload();
    },
    
    getAccessToken: () => {
        return StorageUtil.getItemObject('AUTH')?.accessToken;
    },
    
    getUserId: () => {
        return StorageUtil.getItemObject('AUTH')?.userId;
    },
    
    getUsername: () => {
        return StorageUtil.getItemObject('AUTH')?.userName;
    },
    
    getUser: () => {
        return StorageUtil.getItemObject('AUTH_USER')[0]?.user;
    },
    
    isAuthenticated: () => {
        return StorageUtil.getItemObject('AUTH')!=null;
    },
    
    setUrlWithLogin: (data) => {
        return StorageUtil.setItemObject('AUTH_URL', data)
    },
    
}